import * as Arr from "Everlaw/Core/Arr";
import Button = require("Everlaw/UI/Button");
import Core = require("Everlaw/Core");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import { wrapReactComponent } from "Everlaw/UI/ReactWidget";
import { Hash } from "Everlaw/UrlHash";
import Help = require("Everlaw/Help");
import Icon = require("Everlaw/UI/Icon");
import Input = require("Everlaw/Input");
import LoginCookies = require("Everlaw/LoginCookies");
import LoginRedirect = require("Everlaw/LoginRedirect");
import { SamlState } from "Everlaw/Organization";
import { DataPrimitive } from "Everlaw/Base";
import Rest = require("Everlaw/Rest");
import SingleSelect = require("Everlaw/UI/SingleSelect");
import Str = require("Everlaw/Core/Str");
import UI_Validated = require("Everlaw/UI/Validated");
import UrlHash = require("Everlaw/UrlHash");
import { ValidatedSubmit } from "Everlaw/UI/ValidatedSubmit";
import event = require("dojo/_base/event");
import { queryToObject } from "Everlaw/Core/Obj";
import dojo_on = require("dojo/on");
import * as React from "react";
import { Icon as ReactIcon, IconButton } from "design-system";

const usernameSection = Dom.byId("username-section");
const usernameField = new UI_Validated.Text({
    name: "username",
    inputClass: "text-box-with-icon",
    inputName: "username",
    textBoxAriaLabel: "Email address",
    placeholderMessage: "Enter email",
    spellcheck: false,
});
const passwordSection = Dom.byId("password-section");
const passwordField = new UI_Validated.Password({
    name: "password",
    inputClass: "text-box-with-icon",
    inputName: "password",
    textBoxAriaLabel: "Password",
});
const MAX_NUM_ORG_BUTTONS = 2;
let queryParams: Hash = UrlHash.getQueryParams();
const ssoSection = Dom.byId("sso-section");
const noPasswordSection = Dom.byId("no-password-section");
const emailResetMsg = Dom.byId("no-password-email-reset-msg");
const supportResetMsg = Dom.byId("no-password-contact-support-reset-msg");
const dividerSection = Dom.byId("login-divider");
const titleSection = Dom.byId("title-section");
const switchUserSection = Dom.byId("switch-user-section");
const userIcon = new Icon("user-light", {
    alt: JSP_PARAMS.Server.isSbFree ? "email address" : "email or username",
});
const passwordIcon = new Icon("lock-light", { alt: "password" });

interface InitParams {
    errorMessage?: string;
}

export function init(params: InitParams): void {
    //Populate all the DOM element before displaying the login content to avoid
    //content jumping.
    if (shouldShowLoginAdvertise()) {
        showLoginAdvertise();
    }
    Dom.removeClass(Dom.byId("login-content"), "hidden");

    window.name = "Main";
    Dom.place(usernameField.getNode(), usernameSection, "first");
    Dom.addClass(usernameField.getNode(), "text-input-container");

    Dom.place(userIcon, usernameField.input, "last");
    Dom.addClass(userIcon, "left-icon");

    Dom.place(passwordField.getNode(), passwordSection, "first");
    Dom.addClass(passwordField.getNode(), "text-input-container");

    Dom.place(passwordIcon, passwordField.input, "last");
    Dom.addClass(passwordIcon, "left-icon");

    displayBackendErrorMsg(params.errorMessage);

    // dojo_cookie automatically URL-decodes the cookie value
    const username = LoginCookies.getReturningUser();
    if (username) {
        setUsername(username);
    } else {
        firstTimeLogin();
    }

    Dom.ifNodeExists("region-select", (regionSelect) => {
        dojo_on(regionSelect, "focusin", () => Dom.addClass(regionSelect, "expanded"));
        dojo_on(regionSelect, "focusout", () => Dom.removeClass(regionSelect, "expanded"));
    });

    if (JSP_PARAMS.Server.isSbFree) {
        toggleStorybuilder();
    }
}

function firstTimeLogin() {
    LoginCookies.clear();
    queryParams = null;
    usernameField.focus();

    Dom.style(
        new ValidatedSubmit({
            forms: [usernameField],
            buttonParams: {
                label: "Next",
                parent: "next-button",
                class: "important safe",
                width: "100%",
                onClick: () => {
                    clearLoginErrorMessage();
                    setUsername(usernameField.getValue());
                },
            },
        }),
        "width",
        "100%",
    );
}

interface IDPQuery extends UsernameQuery {
    projectId?: string;
    orgId?: string;
}

function queryUsernameProjectIdOrgId(): IDPQuery {
    const query: IDPQuery = queryUsername();
    const projectId: string = assignedProject();
    if (projectId) {
        query["projectId"] = projectId;
    }
    const orgId: string = assignedOrg();
    if (orgId) {
        query["orgId"] = orgId;
    }
    return query;
}

interface IDPResponse {
    hasEmail: boolean;
    showPassword: boolean;
    assignedOrgId: number;
    assignedProjectName?: string;
    assignedOrgName?: string;
    orgSamls: OrgSaml[];
}

interface OrgSaml {
    orgId: number;
    orgName: string;
    entityId: string;
    samlState: SamlState;
    className: "OrgSaml";
}

function setUsername(username: string) {
    Dom.setContent(titleSection, username);
    Dom.show(userIcon);
    Dom.hide(usernameSection);
    usernameField.setValue(username);

    Dom.show(switchUserSection);
    dojo_on(Dom.byId("switch-user"), Input.tap, () => {
        LoginCookies.clear();
        location.reload();
    });

    Rest.post("/idp.rest", queryUsernameProjectIdOrgId()).then((response: IDPResponse) => {
        // If the user can only sign in via password:
        if (
            response == null
            || ((!response.orgSamls || response.orgSamls.length === 0) && response.showPassword)
        ) {
            addAssignedProjectOrOrgMsg(response, false);
            showPasswordSection();
            // If the user can only sign in via SSO:
        } else if (response.orgSamls && response.orgSamls.length !== 0 && !response.showPassword) {
            addAssignedProjectOrOrgMsg(response, false);
            showSsoSection(response.orgSamls);
            // If the user cannot sign in and has an email address:
        } else if (response.hasEmail && !response.showPassword) {
            showNoPasswordHasEmailSection();
            // If the user cannot sign in and has no email address:
        } else if (!response.hasEmail && !response.showPassword) {
            showNoPasswordNoEmailSection();
            // If the user can sign in either way and the cached authentication method is SSO:
        } else if (LoginCookies.isLoggedInWithSso() && !hasAssignedOrgOrProject()) {
            showSsoSection(response.orgSamls);
            // If the user can sign in either way and the cached authentication method is via a password:
        } else if (LoginCookies.isLoggedInWithPassword() && !hasAssignedOrgOrProject()) {
            showPasswordSection();
            // If the user can sign in either way and there is no cached authentication method:
        } else {
            addAssignedProjectOrOrgMsg(response, true);
            if (hasAssignedOrgOrProject() && isAssignedOrgSSORequired(response)) {
                showSsoSection(response.orgSamls);
            } else {
                showSsoSection(response.orgSamls);
                Dom.show(dividerSection);
                showPasswordSection();
            }
        }
    });
}

function hasAssignedOrgOrProject() {
    return assignedOrg() || assignedProject();
}

function isAssignedOrgSSORequired(response: IDPResponse) {
    return (
        response.assignedOrgId
        && response.orgSamls
        && response.orgSamls.some(
            (saml) =>
                saml.orgId === response.assignedOrgId
                && Core.equals(saml.samlState, SamlState.REQUIRED),
        )
    );
}

function assignedProject() {
    return queryParams && queryParams["projectId"];
}

function assignedOrg() {
    return queryParams && queryParams["orgId"];
}

function addAssignedProjectOrOrgMsg(response: IDPResponse, hasSSOPwd: boolean) {
    const assignedProjectOrOrg = assignedProject()
        ? ["project ", Dom.b(response.assignedProjectName)]
        : assignedOrg()
          ? ["organization ", Dom.b(response.assignedOrgName)]
          : null;
    if (assignedProjectOrOrg) {
        Dom.place(
            Dom.div(
                { class: "h-spaced-8" },
                new Icon("alert-triangle-20").node,
                Dom.span(
                    "Please login with the following ",
                    Str.pluralForm("method", hasSSOPwd ? 2 : 1),
                    " to access ",
                    assignedProjectOrOrg,
                ),
            ),
            usernameSection,
            "after",
        );
    }
}

function showSsoSection(orgSamls: OrgSaml[]) {
    if (!orgSamls || orgSamls.length === 0) {
        return;
    }

    Dom.show(ssoSection);

    // This is a design decision. If there are more than 2 orgs we display them with SingleSelect.
    // Otherwise, we display the orgs in individual buttons.
    if (orgSamls.length > MAX_NUM_ORG_BUTTONS) {
        const orgSelect = new SingleSelect<DataPrimitive<OrgSaml>>({
            elements: orgSamls.map(
                (orgSaml) => new DataPrimitive<OrgSaml>(orgSaml, orgSaml.orgId, orgSaml.orgName),
            ),
            colorItems: null,
            placeholder: "Enter or select organization",
            selectDiv: Dom.byId("sso-orgs-selection"),
            headers: false,
            popup: "after",
            clickableIcon: true,
            textBoxParams: {
                icon: new Icon("caret-down-20"),
                iconPosition: "after",
            },
            onSelect: (ignored, unused, selector) => {
                selector.blur();
            },
        });

        new Button({
            label: "Log in via single sign-on",
            parent: "sso-button",
            class: "important safe",
            width: "100%",
            loadingLabel: "Logging in via single sign-on",
            onClick: (evt: Event, button: Button) => {
                event.stop(evt);
                button.setLoading(true);
                const selectedValue = orgSelect.getValue();
                selectedValue
                    ? ssoLogin(selectedValue.data)
                    : Dialog.ok(
                          "Organization not set",
                          "Please select an organization to login via single sign-on",
                      );
            },
        }).focus();
    } else {
        orgSamls.forEach((orgSaml: OrgSaml) =>
            new Button({
                label: "Log in to " + orgSaml.orgName + " organization",
                parent: "sso-button",
                class: "important safe",
                width: "100%",
                loadingLabel: "Logging in to " + orgSaml.orgName + " organization",
                onClick: (evt: Event, button: Button) => {
                    event.stop(evt);
                    button.setLoading(true);
                    ssoLogin(orgSaml);
                },
            }).focus(),
        );
    }
}

/* We must pass the target URL and hash string along to the SAML entry point, so it can redirect
 * the user after authentication is done.
 */
function ssoLogin(orgSaml: OrgSaml) {
    LoginCookies.setSsoLogin(orgSaml.entityId);
    const target = LoginRedirect.isDefault() ? undefined : LoginRedirect.target;
    location.assign(LoginCookies.getSsoUrl(orgSaml.orgId.toString(), target));
}

function showPasswordSection(focus = true) {
    Dom.show(passwordSection);
    focus && passwordField.focus();
    Dom.style(
        new ValidatedSubmit({
            buttonParams: {
                label: "Log in with password",
                parent: "login-button",
                class: "important safe",
                loadingLabel: "Logging in with password",
                onClick: (_evt: Event, button: Button) => {
                    button.setLoading(true);
                    passwordLogin();
                },
                width: "100%",
            },
            forms: [passwordField],
        }),
        "width",
        "100%",
    );
    dojo_on(Dom.byId("forgot-password"), Input.tap, resetPassword);
}

function passwordLogin() {
    clearLoginErrorMessage();

    const loginBox = Dom.byId("login-content");
    gsap.to(loginBox, { duration: 0.5, opacity: 0 });

    Rest.formSubmit(Dom.byId("login_form") as HTMLFormElement).then(
        () => {
            LoginCookies.setPasswordLogin();
            LoginRedirect.go();
        },
        (err: Rest.Failed) => {
            gsap.killTweensOf(loginBox);
            passwordField.setIncorrectValue(err.message || "Invalid username or password");
            Dom.style(loginBox, "opacity", "1");
        },
    );
    return false;
}

function showNoPasswordHasEmailSection() {
    Dom.show(noPasswordSection);
    Dom.show(emailResetMsg);
    new Button({
        label: "Reset Password",
        parent: "reset-button",
        class: "important safe",
        width: "100%",
        onClick: (evt: Event) => {
            event.stop(evt);
            resetPassword();
        },
    });
}

function showNoPasswordNoEmailSection() {
    Dom.show(noPasswordSection);
    Dom.show(supportResetMsg);
}

function displayBackendErrorMsg(errorMessage: string) {
    errorMessage && addErrorMessage(errorMessage);

    const msg: string = Arr.wrap(
        queryToObject(location.search.slice(1).replace(/\+/g, "%20")).message,
    )[0];
    if (msg) {
        let message: Dom.Content;
        // These error keys must match the ones used by their controllers.
        // Currently these are used in UserController and UserProfileController.
        if (msg === "userGroupError") {
            message =
                "The user group on the project you were invited to no longer exists. Please have"
                + " your administrator first cancel this invitation, then send you a new one.";
        } else if (msg === "inviteError") {
            const login = window.location.origin + "/login.do";
            message = [
                "Invalid or expired invitation.",
                Dom.p(
                    "If you have already set up an account, please log in with your username and password.",
                ),
                Dom.p(
                    "To access this page, please visit or bookmark ",
                    Dom.a({ href: login }, login),
                ),
            ];
        } else if (msg === "resetIdError") {
            message = "Invalid or expired reset ID.";
        }
        addErrorMessage(message);
    }
}

function addErrorMessage(message: Dom.Content) {
    if (message) {
        Dom.addContent(
            "login-error-section",
            Dom.div(
                { class: "login-error h-spaced-8" },
                new Icon("alert-triangle-red-20").node,
                Dom.div(message),
            ),
        );
        Dom.removeClass("login-error-section", "hidden");
    }
}

function clearLoginErrorMessage() {
    Dom.empty("login-error-section");
    Dom.addClass("login-error-section", "hidden");
}

/**
 * The endpoint will always return 200 regardless of whether or not the username exists, and the
 * same message is always shown. This is to prevent username enumeration based on the dialog shown
 * after trying to reset a password. The only exception is when a badly formatted email is provided
 * (e.g. 'test@'), in which cases a message will be displayed indicating the email provided is not
 * formatted correctly.
 */
function resetPassword() {
    const username = queryUsername();
    Rest.post("/resetPassword.rest", username).then(
        () => {
            // We don't want to allow possible user enumeration in this functionality, so we always get
            // success.
            const dialogBody = Dom.div(
                "If there is an account and email associated with the username "
                    + "provided, a reset link will be emailed to you.",
            );
            Help.addHelp(dialogBody, "Need assistance?");
            Dialog.ok("Reset link sent", dialogBody);
        },
        (data) => {
            const errorBody = Dom.div(Dom.div({ class: "forgot-error" }, data.message));
            Help.addHelp(errorBody, "Need assistance?");
            Dialog.ok("Error", errorBody);
        },
    );
}

interface UsernameQuery {
    username?: string;
    email?: string;
}

function queryUsername(): UsernameQuery {
    if (usernameField.getValue().indexOf("@") < 0) {
        return { username: usernameField.getValue() };
    } else {
        return { email: usernameField.getValue() };
    }
}

function toggleStorybuilder() {
    Dom.addClass("login-box", "storybuilder");
    Dom.addClass("more-info", "storybuilder");
    Dom.replaceClass("switch-user", "everblue-link", "everblue-link-bold");
    Dom.replaceClass("forgot-password", "everblue-link", "everblue-link-bold");
    new Button({
        label: "Sign up at no cost",
        parent: "signup-redirect-button",
        class: "safe",
        width: "329px",
        onClick: (evt: Event) => {
            location.assign("/users/signup.do");
        },
    });
}

function shouldShowLoginAdvertise(): boolean {
    return !LoginCookies.isAdvertiseOptOut();
}

function getLoginImageInfo(): { src: string; alt: string } {
    // Adding the prefix to let nginx add the cache policy header
    const prefix = "/ver/" + JSP_PARAMS.Versioning.assetVersion;
    return {
        src: `${prefix}/images/login-advertisement.jpg`,
        alt: "Learn how to explore documents at a glance with Everlaw’s data visualization webinar series.",
    };
}

function showLoginAdvertise() {
    // Set the container and other content to have the correct CSS to be able to show the
    // advertisement
    const contentContainer = Dom.byId("login-content-container");
    Dom.addClass(contentContainer, "container-advertise-display");
    Dom.addClass(Dom.byId("login-content"), "login-content-advertise-display");
    const adLink =
        "https://everlaw.registration.goldcast.io/series/11976e66-a6de-4d38-b94e-a637d3482e5f?utm_source=everlaw-platform&utm_medium=organic&utm_campaign=fy25q4_customer+webinar_vyd+series:+data+visualizer&utm_content=loginad";
    // Use the advertise-content-placeholder class to occupy the space before the images are loaded
    const advertisement = Dom.div(
        {
            id: "advertise-container",
            class: "advertise-content advertise-content-placeholder",
        },
        Dom.a({ id: "advertise-link", href: adLink, target: "_blank" }),
    );
    const loginImageInfo = getLoginImageInfo();
    addImage(loginImageInfo.src, loginImageInfo.alt);
    Dom.place(advertisement, contentContainer);
}

function addImage(src: string, alt: string): void {
    const img = new Image();
    img.alt = alt;
    img.className = "login-advertise";
    img.onload = () => {
        Dom.addContent(Dom.byId("advertise-link"), img);
        //Remove the placeholder width when the image are loaded.
        Dom.removeClass(Dom.byId("advertise-container"), "advertise-content-placeholder");
        const removeButton = wrapReactComponent(IconButton, {
            children: React.createElement(ReactIcon.X),
            "aria-label": "Dismiss login promotion",
            onClick: () => {
                Dom.remove(Dom.byId("advertise-container"));
                LoginCookies.setAdvertiseOptOut();
            },
        });
        Dom.addClass(removeButton, "dismiss-ad-button");
        Dom.setAttr(removeButton, "id", "dismiss-ad-button");
        Dom.place(Dom.node(removeButton), Dom.byId("advertise-container"));
    };
    img.src = src;
}
